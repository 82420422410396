<template>
  <section v-if="areThereAnyCampaigns!==null">
    <div v-if="areThereAnyCampaigns">
      <campaign-filter-section
        v-if="filtersToggled"
        :order-by="orderBy"
        @show-archived-updated="loadCampaigns"
        @update-order-by="updateOrderBy"
      />
      <div class="campaign-list-wrapper">
        <campaign-list-header />
        <ul class="campaign-list">
          <campaign-list-item
            v-for="campaign in sortedCampaigns"
            :key="`${campaign.uuid}-${campaign.archived}`"
            :campaign="campaign"
            :can-toggle-campaign="Boolean(campaign.designs_count>0 && !campaign.archived)"
            :row-actions="getRowActions(campaign)"
            :show-pin-button="showPinButton"
            @update-campaign-properties="updateCampaignProperties"
            @archive-campaign="archiveCampaign"
            @create-design="createDesign"
            @pin-campaign="pinCampaign"
            @toggle-campaign="toggleCampaign"
            @unarchive-campaign="unarchiveCampaign"
          />
        </ul>
        <div
          v-if="currentPage!==lastPage"
          class="w-100 d-flex justify-content-center align-items-center mb-3"
        >
          <lottie-animation
            v-if="isLoading"
            height="100px"
            width="100px"
            :loop="true"
          />
        </div>
      </div>
    </div>
    <empty-section
      v-else
      :icon="require('../../../images/dashboard/designer/campaigns-empty-state.svg')"
    >
      There are no Campaigns yet. Start by creating new one!
    </empty-section>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex"
import Utils from "../../utils"
import CampaignListHeader from "./CampaignListHeader"
import CampaignListItem from "./CampaignListItem"
import CampaignModal from "../common/modal/campaign_modal/CampaignModal"
import DeleteModal from "../common/modal/DeleteModal"
import Language from "../../i18n/en"
import DesignModal from "../common/modal/DesignModal"
import ConfirmModal from "../common/modal/ConfirmModal"
import CampaignFilterSection from "./Filters"
import EmptySection from "../dashboard/components/EmptySection"
import ImportModal from "@/components/modals/ImportModal.vue"
import { CompanyRoutes } from "../../api/routes"
import LottieAnimation from "../LottieAnimation.vue"

export default {
  name: "CampaignsList",
  components: {
    CampaignListHeader,
    CampaignListItem,
    CampaignFilterSection,
    EmptySection,
    LottieAnimation
  },
  props: {
    preselect: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      count: 0,
      currentPage: 1,
      lastPage: 10,
      filtersToggled: false,
      campaigns: [],
      campaignsLoaded: false,
      areThereAnyCampaigns: null,
      orderBy: "last_edit",
      isLoading: false
    }
  },
  computed: {
    ...mapState(["selectedFilters", "currentUser"]),
    sortedCampaigns () {
      let sortByDate
      if (this.orderBy === "created_at") {
        sortByDate = (a, b) => new Date(b.created_at) - new Date(a.created_at)
      } else {
        sortByDate = (a, b) => new Date(b.last_edit) - new Date(a.last_edit)
      }

      const sortedCampaigns = [
        ...this.campaigns.filter(c => c.pin).sort(sortByDate),
        ...this.campaigns.filter(c => !c.pin).sort(sortByDate)
      ]

      if (!this.preselect) return sortedCampaigns

      const preselected = sortedCampaigns.find(c => c.unique_id === this.preselect)
      if (!preselected) return sortedCampaigns

      return [
        preselected,
        ...sortedCampaigns.filter(c => c.unique_id !== this.preselect)
      ]
    },
    showPinButton () {
      return Boolean(this.currentUser && (this.currentUser.roles.manager || this.currentUser.roles.admin || this.currentUser.roles.super_admin))
    },
    showArchivedCampaigns () {
      return Boolean(this.currentUser?.settings.showArchived)
    }
  },
  watch: {
    selectedFilters: {
      handler: "loadCampaigns",
      deep: true
    }
  },
  beforeUnmount () {
    this.$eventHub.$off("load-campaigns", this.loadCampaigns)
    this.$eventHub.$off("campaign-updated", this.updateCampaign)
    this.$eventHub.$off("toggle-filters", this.toggleFilters)
    window.removeEventListener("intercomTourChanged", this.loadCampaigns)
    document.querySelector(".app-container").removeEventListener("scroll", this.handleScroll)
    if (this.currentUser && this.currentUser?.active_company?.uuid) {
      this.destroyCampaignDesignsImportWsConnection({ companyUuid: this.currentUser?.active_company?.uuid
      })
    }
  },
  created () {
    // Event emitted after creating a campaign via campaign wizard
    this.$eventHub.$on("load-campaigns", this.loadCampaigns)
    // Event emitted after editing a campaign via campaign wizard
    this.$eventHub.$on("campaign-updated", this.updateCampaign)
    this.$eventHub.$on("toggle-filters", this.toggleFilters)
    window.addEventListener("intercomTourChanged", this.loadCampaigns)

    this.loadCurrentUser().then(() => {
      this.loadCampaigns()

      const url = CompanyRoutes.getCompanySettings(this.currentUser?.active_company?.uuid)
      this.$apiClient.get(url).then((res) => {
        this.orderBy = res.data.data.settings.sortCampaignsBy
        this.loadCampaigns()
      })
    })

  },
  mounted () {
    const container = document.querySelector(".app-container")
    if (container) {
      container.addEventListener("scroll", this.handleScroll)
    }

    if (this.currentUser && this.currentUser?.active_company?.uuid) {
      this.setupCampaignDesignsImportWsConnection({
        companyUuid: this.currentUser?.active_company?.uuid,
        callback: this.campaignImportCallback
      })
    }
  },
  methods: {
    ...mapActions(["fetchCampaign", "fetchCampaigns", "loadCurrentUser", "areThereAnyCampaignsInTheCurrentCompany", "setupCampaignDesignsImportWsConnection", "destroyCampaignDesignsImportWsConnection"]),
    updateSlideEffect (val) {
      this.slideEffect = val.value
    },
    updateOrderBy (option) {
      this.orderBy = option.value
      this.loadCampaigns()
    },
    updateCampaignProperties ({ campaignUuid, newProperties }) {
      const campaign = this.campaigns.find(c => c.uuid === campaignUuid)
      if (!campaign) return

      Object.entries(newProperties).forEach(property => {
        campaign[property[0]] = property[1]
      })
    },
    campaignImportCallback () {
      this.$toasted.error(Language.designs.success.import_campaign_finished)
    },
    checkIfThereAnyCampaignsInTheCurrentCompany () {
      this.areThereAnyCampaignsInTheCurrentCompany().then(res => {
        this.areThereAnyCampaigns = Boolean(res.isAnyCampaign)
      })
    },
    handleScroll () {
      if (this.isLoading) return

      const container = document.querySelector(".app-container")
      if (!container) return

      const { scrollTop, clientHeight, scrollHeight } = container
      const threshold = 300 // pixels from bottom

      if (scrollTop + clientHeight >= scrollHeight - threshold) {
        if (this.currentPage < this.lastPage) {
          this.fetchData(this.currentPage + 1)
        }
      }
    },
    updateCampaign (campaign) {
      const campaignToUpdate = this.campaigns.find(c => c.uuid === campaign.uuid)
      if (campaignToUpdate) {
        Object.entries(campaign).forEach(property => {
          campaignToUpdate[property[0]] = property[1]
        })
      }
    },
    getRowActions (campaign) {
      if (this.currentUser && (this.currentUser.roles.manager || this.currentUser.roles.admin || this.currentUser.roles.super_admin) === false) {
        return [
          {
            content: "Import",
            icon: "import",
            click: ($event) => this.openImportModal($event, campaign.uuid)
          }
        ]
      }

      const actions = []
      if (!campaign.archived) {
        actions.push({
          content: "Edit",
          icon: "edit",
          click: ($event) => this.openEditCampaignModal($event, campaign.uuid)
        })
      }
      if (campaign.permissions.edit) {
        actions.push({
          content: "Analytics",
          icon: "analytics",
          href: `/campaign/${campaign.unique_id}/analytics`
        })
      }

      actions.push({
        content: "Import",
        icon: "import",
        click: ($event) => this.openImportModal($event, campaign.uuid)
      })

      if (!campaign.archived) {
        actions.push({
          content: "Duplicate",
          icon: "duplicate",
          href: `/campaign/${campaign.unique_id}/duplicate`,
          click: ($event) => this.duplicateCampaign($event, campaign.uuid)
        })
      }
      if (!campaign.archived) {
        actions.push({
          content: "Publish",
          icon: "publish",
          href: "/adtags/" + campaign.unique_id + "?token=" + campaign.edit_token
        })
      }
      if (campaign.archived) {
        actions.push({
          content: "Unarchive",
          icon: "archive",
          click: () => this.unarchiveCampaign(campaign.uuid)
        })
      } else {
        actions.push({
          content: "Archive",
          icon: "archive",
          click: () => this.archiveCampaign(campaign.uuid)
        })
      }

      if (campaign.permissions.delete && (this.currentUser && this.currentUser.roles && this.currentUser.roles.super_admin)) {
        actions.push({
          content: "Delete",
          icon: "delete",
          click: () => {
            this.destroyCampaign(campaign.uuid)
          }
        })
      }
      return actions
    },
    archiveCampaign (campaignUuid) {
      this.$modal.show(ConfirmModal, {
        title: "Archive campaign",
        description: "Are you sure to archive this campaign?",
        actionButton: "Archive",
        isWarning: true,
        confirm: () => {
          this.$store.dispatch("archiveCampaign", campaignUuid).then(() => {
            const campaign = this.campaigns.find(c => c.uuid === campaignUuid)
            if (campaign) {
              campaign.archived = true
              campaign.active = false
            }
            this.$toasted.success(Language.campaign.success.archived)
          }).catch(() => {
            this.$toasted.error(Language.campaign.error.archived)
          })
        }
      })
    },
    unarchiveCampaign (campaignUuid) {
      this.$modal.show(ConfirmModal, {
        title: "Unarchive campaign",
        description: "Are you sure to unarchive this campaign?",
        actionButton: "Unarchive",
        isWarning: true,
        confirm: () => {
          this.$store.dispatch("unarchiveCampaign", campaignUuid).then(() => {
            const campaign = this.campaigns.find(c => c.uuid === campaignUuid)
            if (campaign) {
              campaign.archived = false
              this.$toasted.success(Language.campaign.success.unarchived)
            }
          }).catch(() => {
            this.$toasted.error(Language.campaign.error.unarchived)
          })
        }
      })
    },
    duplicateCampaign ($event, campaignUuid) {
      $event.preventDefault()

      this.$store.dispatch("duplicateCampaign", campaignUuid).then(response => {
        const campaign = response.data
        campaign.active = false
        this.campaigns.unshift(campaign)
        this.$toasted.success(Language.campaign.success.duplicate)
      }).catch(err => {
        if ([419].includes(err.response.status)) return
        if (err.response.status === 400) {
          this.$toasted.error(err.response.data.message)
        } else {
          this.$toasted.error(Language.campaign.error.duplicate)
        }
      })
    },
    createDesign (campaignUuid) {
      const campaign = this.campaigns.find(campaign => campaign.uuid === campaignUuid)
      this.$modal.show(DesignModal, { campaign })
    },
    destroyCampaign (campaignUuid) {
      this.$modal.show(DeleteModal, {
        title: "Delete campaign",
        description: "Are you sure to delete this campaign with all designs?",
        actionButton: Language.confirm_delete,
        successMessage: Language.campaign.success.delete,
        errorMessage: Language.campaign.error.delete,
        action: () => {
          return this.$store.dispatch("destroyCampaign", campaignUuid).then(response => {
            if (response.data.success) {
              this.campaigns = this.campaigns.filter(campaign => campaign.uuid !== campaignUuid)
            } else {
              this.$toasted.info(Language.campaign.error.delete)
            }
          }
          )
        }
      })
    },
    loadCampaigns () {
      this.checkIfThereAnyCampaignsInTheCurrentCompany()
      this.fetchData(1, 15, this.orderBy, "DESC", false)
      this.campaignsLoaded = false
    },
    toggleFilters (section) {
      if (section === "campaign-list-filters") {
        this.filtersToggled = !this.filtersToggled
      }
    },
    openEditCampaignModal ($event, campaignUuid) {
      $event.preventDefault()

      const campaign = this.campaigns.find(campaign => campaign.uuid === campaignUuid)

      this.$modal.show(CampaignModal, { campaign, editCampaign: true })
    },
    openImportModal ($event, campaignUuid) {
      $event.preventDefault()

      const campaign = this.campaigns.find(campaign => campaign.uuid === campaignUuid)

      this.$modal.show(ImportModal, {
        campaignUniqueId: campaign.unique_id,
        callback: () => {
          this.loadCampaigns()
        }
      })
    },
    pinCampaign (campaignUuid) {
      const campaign = this.campaigns.find(c => c.uuid === campaignUuid)
      if (campaign) {
        this.$store.dispatch("pinCampaign", { id: campaign.unique_id, pin: !campaign.pin }).then(() => {
          const campaignToUpdate = this.campaigns.find(c => c.uuid === campaign.uuid)
          campaignToUpdate.pin = !campaign.pin
          campaignToUpdate.last_edit = new Date().toISOString()
        })
      }
    },
    fetchData (page = 1, limit = 5, sortBy = "last_edit", sortWay = "DESC", concat = true) {
      this.isLoading = true
      const loadCampaigns = this.fetchCampaigns({
        limit,
        page,
        orderBy: sortBy,
        order: sortWay,
        filters: this.getFilters(),
        concat
      })

      loadCampaigns.then((resp) => {
        this.count = resp.meta.total
        this.currentPage = resp.meta.current_page
        this.lastPage = resp.meta.last_page

        const campaigns = this.prepareCampaigns(resp.data)

        if (concat) {
          this.campaigns = Utils.removeDuplicatesByKey([...this.campaigns, ...campaigns], "uuid")
        } else {
          this.campaigns = campaigns
        }

        // show initial campaign
        if (this.preselect && !this.campaignsLoaded) {
          this.fetchCampaign(this.preselect).then(campaign => {
            campaign.active = Boolean(!campaign.archived && campaign.designs_count > 0)
            this.campaigns = Utils.removeDuplicatesByKey([campaign, ...this.campaigns], "uuid")
          })
        }
        this.isLoading = false
        this.handleScroll()
      })
    },
    prepareCampaigns (campaigns) {
      return campaigns.map(campaign => {
        if (!campaign.active) {
          campaign.active = false
        }
        return campaign
      })
    },
    updateUrl (campaign) {
      if (campaign.active) {
        window.history.replaceState({}, "", `/campaign/${campaign.unique_id}/details`)
      } else {
        const activeCampaign = this.campaigns.find(c => c.active)
        if (activeCampaign) {
          window.history.replaceState({}, "", `/campaign/${activeCampaign.unique_id}/details`)
        } else {
          window.history.replaceState({}, "", "/campaigns")
        }
      }
    },
    toggleCampaign (campaignUuid) {
      this.campaigns.forEach(campaign => {
        const hasDesigns = campaign.designs_count > 0
        if (campaign.uuid === campaignUuid && hasDesigns) {
          campaign.active = !campaign.active
          this.updateUrl(campaign)
        } else {
          campaign.active = false
        }
      })
    },
    getFilters () {
      return {
        tag: this.selectedFilters?.tag ? this.selectedFilters.tag.map((row) => row.value) : [],
        brand: this.selectedFilters?.brand ? this.selectedFilters.brand.map((row) => row.value) : [],
        status: this.selectedFilters?.status ? this.selectedFilters.status.map((row) => row.value) : [],
        campaign: this.selectedFilters?.campaign ? this.selectedFilters.campaign.map((row) => row.value) : [],
        archived: this.showArchivedCampaigns
      }
    }
  }
}
</script>
